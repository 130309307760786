<template>
  <div>
    <lightbox :appointment="activeAppointment" :APIENDPOINT="APIENDPOINT" v-if="openLightbox"/>
    <div class="justify-center col-12 mx-auto">
          <h1 class="mb1 mt3 px3">Zu bearbeitende Termine</h1>
            <div class="appointment-table mt3 flex flex-wrap" style="background-color: #525252;">
                <div v-for="appointment in appointments" :key="appointment.id" class="flex col-6 border-box px2 py2 pointer justify-between overflow-hidden" style="align-items: center;" @click="openSignLightbox(appointment)">
                  <div class="flex">
                  <div style="min-width: 80px;">{{appointment.bookingcode}}</div> 
                  <div class="mx2" style="min-width: 60px;">{{appointment.time}}</div> 
                  <div>{{appointment.customer_name}}</div>
                  </div>
                  <div style=""><img style="width: 1rem; margin-bottom: -4px; margin-left: .5rem;" src="./assets/pen_icon.svg"/></div>
                </div>
            </div>
    </div>
  </div>
</template>

<script>

import { EventBus } from "@/event-bus.js";
import Lightbox from './Lightbox.vue';

export default {
  name: 'AppointmentTable',
  props: {
    appointments: Array,
    APIENDPOINT: String
  },
  data() {
    return {
      openLightbox: false,
      activeAppointment: {},
      pullRate: 30,
    }
  },
  created() {
    EventBus.$on('close_lightbox', () => {
      this.openLightbox = false;
    })
  },
  mounted(){
    this.getDataTimer();
  },
  beforeDestroy(){
    EventBus.$off('close_lightbox')
  },
  watch:{
  
  },
  components: {
    'lightbox': Lightbox
  },
  methods: {
    openSignLightbox(appointment){
      console.log(appointment);
      this.openLightbox = true;
      this.activeAppointment = appointment
    },
    getDataTimer(){
      let that = this
      let seconds = 0;

      // timer for the axios call
      function timeUntilPull() {
        seconds++; 
        if (seconds === that.pullRate) {
            that.pullData()
            seconds = 0  //reset timer        
        }
      }
      setInterval(timeUntilPull, 1000); 
    },
    pullData(){
      console.log('pulling data...')
      EventBus.$emit('dataRefresh');
    }
  }
}
</script>

<style scoped>
.appointment-table{
  padding-top: 1px;
  padding-right: 1px;
  font-size: .85rem;
}
.appointment-table > div {
  background-color: #fff;
  border-left: 1px solid #525252;
  margin-bottom: 1px
}
  .appointment-table > div:nth-child(3n){
    background-color: #f4f4f4;
  }
    .appointment-table > div:nth-child(4n){
    background-color: #f4f4f4;
  }
</style>