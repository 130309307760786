<template>
    <div class="justify-center col-12 mx-auto">
            <div class="max-width-3 mx-auto p3 mt3 border-box drop-shadow">
              <h2 class="mb3">Wählen Sie ihr Testzentrum</h2>
              <div class="flex flex-column" style="gap: 2rem;">
                <submit-button name="Testzentrum Zurbrüggen" :validInput="true" event_name="choose_center_01" :btnAnimation="false"/>
                <submit-button name="Testzentrum an der Graft" :validInput="true" event_name="choose_center_02" :btnAnimation="false"/>
                <submit-button name="Test für Firmen" :validInput="true" event_name="choose_center_03" :btnAnimation="false"/>
                <submit-button name="PCR-Tests" :validInput="true" event_name="choose_center_04" :btnAnimation="false"/>
                <submit-button name="Tests für Stadtverwaltung" :validInput="true" event_name="choose_center_05" :btnAnimation="false"/>
              </div>
            </div>
    </div>
</template>

<script>

// import { EventBus } from "@/event-bus.js";
import SubmitButton from './SubmitButton.vue';
// import axios from 'axios';

export default {
  name: 'ChooseCenter',
  props: {
  },
  data() {
    return {

    }
  },
  created() {
    //   EventBus.$on('pre_choose_center_01', () => {
    //       EventBus.$emit('choose_center_01')
    //   });
    //   EventBus.$on('pre_choose_center_02', () => {
    //       EventBus.$emit('choose_center_02')
    //   })
    //   EventBus.$on('pre_choose_center_03', () => {
    //       EventBus.$emit('choose_center_03')
    //   })
    //   EventBus.$on('pre_choose_center_04', () => {
    //       EventBus.$emit('choose_center_04')
    //   })
    //   EventBus.$on('pre_choose_center_05', () => {
    //       EventBus.$emit('choose_center_05')
    //   })

  },
  components: {
      'submit-button': SubmitButton
  },
  watch:{
      
  },
  methods: {
  
  }
}
</script>

<style>
 
</style>