<template>
    <div id="app"> 
        <div class="p3 flex justify-start" style="background-color: darkred;">
          <div v-if="loadData" style="text-align: center; margin-top: 0; position: absolute; top: 1.5rem; right: 2rem; pointer-events: none">
            <img src="./assets/ajax-loader.gif">
          </div>
          <a v-if="user_id != ''" class="underline logout-btn white pointer" @click="logout()">Ausloggen</a>
          <a v-if="user_id != ''" class="underline logout-btn ml3 white pointer" @click="dataRefresh()">Aktualisieren</a>
        </div>
        <div class="step-holder mx-auto" v-show="step == 1000">
            <choose-center/>
        </div>
        <div class="step-holder mx-auto" v-show="step == 1001">
            <login
              :appointment="appointment"
              :onBoardingAppointmentTestcenter="testcenter"
              :APIENDPOINT="APIENDPOINT"

            />
        </div>
        <div class="step-holder mx-auto" v-if="step == 1002">
            <appointment-table
              :appointments="appointments_to_be_signed"
              :APIENDPOINT="APIENDPOINT"
            />
        </div>
      
    </div>
</template>

<script>

import Login from './Login.vue'
import AppointmentTable from './AppointmentTable.vue'
import ChooseCenter from './ChooseCenter.vue'


import { EventBus } from "@/event-bus";
import axios from 'axios'

export default {
  name: "app",
  props: ['source','counterpart'],
  data() {
    return {
      debug: true, 
      step: 1000, // INFO: Set this to a diffent number to see further steps, e.g. 100 or 200.
      validate: false,
      testcenter: 1,

      user_id: '',
      user_backend_login_mail: '',
      user_backend_login_password: '',

      loadData: false,

      appointments_to_be_signed: [],
      onboardingData: {
        opportunities_area: "",
        opportunities_producer: "",
        opportunities_type: "",
        opportunities_year: '',
        opportunities_nettoprice: "",
        opportunities_value: "",
        opportunities_amount: "",
        opportunities_charge: "",
        opportunities_category_id: "",
        customers_company: "",
        customers_company_additional: "",
        customers_address: "",
        customers_city: "",
        customers_street: "",
        customers_postal_code: "",
        customers_prename: "",
        customers_surname: "",
        customers_gender: "",
        customer_contact_emailadress: "",
        customer_contact_phonenumber: "",
        tracking_approved: "false"
      },
      appointmentData: {
        customer_appointment_center: "",
        customer_appointment_date: "",
        customer_appointment_time: "",
        customer_appointment_product: "",
        customer_appointment_payment: "",
        customer_appointment_name: "",
        customer_appointment_email: "",
        customer_appointment_phonenumber: "",
        customer_appointment_birthday: "",
        customer_appointment_postalcode: "",
        customer_appointment_address: "",
        customer_appointment_dsgvo: "",
        customer_appointment_coronawarnapp: "",
        customer_appointment_coronawarnapp_type: "",
        imageSRC: "",
        tracking_approved: "false"
      },
      appointment: false,
      apiResponse: {},
      STEP_SELECT_MACHINECATEGORY: 100,
      STEP_SELECT_MANUFACTURER: 200,
      APIENDPOINT: '',
      Endpoints: {
        test_01: process.env.VUE_APP_APIENDPOINT_TEST_01,
        test_02: process.env.VUE_APP_APIENDPOINT_TEST_02,

        live_01: process.env.VUE_APP_APIENDPOINT_01,
        live_02: process.env.VUE_APP_APIENDPOINT_02,
        live_03: process.env.VUE_APP_APIENDPOINT_03,
        live_04: process.env.VUE_APP_APIENDPOINT_04,
        live_05: process.env.VUE_APP_APIENDPOINT_05,
      },
      USE_TEST_API: process.env.VUE_APP_USE_TEST_API,
      SYSTEMTITLE: process.env.VUE_APP_SYSTEMTITLE,
      SECONDTESTCENTER: process.env.VUE_APP_SECONDTESTCENTERACTIVE,
      paymentEnabled: process.env.VUE_APP_SHOW_PAYMENT_OPTION,
    };
  },
  created: function () {


    EventBus.$on('dataRefresh', () => { // Kein payload!
      this.log('App.vue: Event received: dataRefresh');
      this.dataRefresh();
    });
    EventBus.$on('changeStep', (payload) => { 
        console.log('App received changeStep')
        this.log('App.vue: Event received: changeStep');
        this.scrollToTop();
        this.reprint(payload.step_number);
    });
    EventBus.$on('login_success', (payload) => { 
        this.log('App received login_success')
        this.log(payload)
        this.appointments_to_be_signed = payload.appointments_to_be_signed;
        this.user_id = payload.user_id;
        this.user_backend_login_mail =  payload.user_backend_login_mail;
        this.user_backend_login_password =  payload.user_backend_login_password;
        this.scrollToTop();
        this.reprint(1002)    
    });
    EventBus.$on('choose_center_01', () => { 
        this.log('App received choose_center_01')
        this.APIENDPOINT = this.Endpoints.live_01
        this.scrollToTop();
        this.reprint(1002)    
    });
    EventBus.$on('choose_center_02', () => { 
        this.log('App received choose_center_02')
        this.APIENDPOINT = this.Endpoints.live_02
        this.scrollToTop();
        this.reprint(1002)    
    });  
    EventBus.$on('choose_center_03', () => { 
        this.log('App received choose_center_03')
        this.APIENDPOINT = this.Endpoints.live_03
        this.scrollToTop();
        this.reprint(1002)    
    });  
    EventBus.$on('choose_center_04', () => { 
        this.log('App received choose_center_04')
        this.APIENDPOINT = this.Endpoints.live_04
        this.scrollToTop();
        this.reprint(1002)    
    });  
    EventBus.$on('choose_center_05', () => { 
        this.log('App received choose_center_05')
        this.APIENDPOINT = this.Endpoints.live_05
        this.scrollToTop();
        this.reprint(1002)    
    });          
  },
  mounted(){
    if(this.USE_TEST_API == 'TEST_01'){
      console.log('INITIALIZE TEST_01 ENDPOINT');
      this.APIENDPOINT = this.Endpoints.test_01
      this.reprint(1001)
    }
    if(this.USE_TEST_API == 'TEST_02'){
      console.log('INITIALIZE TEST_02 ENDPOINT');
      this.APIENDPOINT = this.Endpoints.test_02
      this.reprint(1001)
    }
    if(this.USE_TEST_API == 'false'){
      console.log('INITIALIZE LIVE_MODE');
      this.APIENDPOINT = ''
    }
  },
  components: {

    'login' : Login,
    'appointment-table' : AppointmentTable,
    'choose-center' : ChooseCenter,
   },
  methods: {
    logout(){
      this.appointments_to_be_signed = [];
      this.user_id = '';
      this.user_backend_login_mail = '';
      this.user_backend_login_password = '';
      if(this.USE_TEST_API == 'TEST_01' || this.USE_TEST_API == 'TEST_02'){
        this.reprint(1001);
      }else{
        this.reprint(1000)
      }
      this.loadData = false;
    },
    dataRefresh(){
      var createURL = this.APIENDPOINT + '/signage/get-unsigned-for-tablets'
      var that = this;
      this.loadData = true;
      axios.post(createURL, {
        backend_login_email: that.user_backend_login_mail,
        backend_login_password: that.user_backend_login_password
      }).then(function (response) {
        if(response.data.login_proof == true){
          console.log('PULLED DATA SUCCESSFULLY')
          console.log(response.data.appointment_to_be_signed)
          that.appointments_to_be_signed = response.data.appointment_to_be_signed;
          that.loadData = false;
        }
        if(response.data.login_proof == false){
          console.log('FAILURE')
          that.errorMsg = response.data.login.message
          that.not_valid_login = true;
        }

      })
      .catch(function (error) {
        that.log('catch Function of AJAX-Called... error()');
        that.log(error);
        that.apiResponse = error;
      });
    },
    reprint: function (newstep) {

        if(newstep == undefined || newstep == '') {
            this.log('ERROR: App.vue: reprint() called with undefined newstep!')
        } else {
            
            EventBus.$emit('dataRefresh', {
                'onboardingData': this.onboardingData
            });

            this.log('App.vue: reprint().called()');
            this.log('App.vue: reprint() Old-Step: ' + this.step);
            this.step = newstep;
            this.log('App.vue: reprint() New-Step: ' + newstep);
        }

        this.log('BOC Collected Data:');
        this.log(this.onboardingData)
    },
    log: function (obj) {
      if(this.debug) {
        console.log(obj)
      }
    },
   

     makeAppointmentRequest() {
      //SEND TO URL
      let createURL = this.APIENDPOINT + '/onboarding/appointments/send';
      
      
      

      let currentObj = this;

      axios.post(createURL, {
        appointmentData: this.appointmentData
      }).then(function (response) {
        currentObj.apiResponse = response.data;
        if(currentObj.apiResponse.cwaActive) {
          currentObj.appointmentData.imageSRC = response.data.cwaData.imageSRC;
        }
        currentObj.log('Then Function of AJAX-Called... called()');
        currentObj.log(currentObj.apiResponse);
        currentObj.reprint(1005); // Alles ist fein.
        if(currentObj.apiResponse.requestBlocked) {
          currentObj.reprint(1013); // Nicht so fein.
        } else { 
          currentObj.reprint(1005); // Alles ist fein.
          EventBus.$emit('submit_step_downloadlink', {
                'onBoardingAppointmentLinkTask': currentObj.apiResponse.downloadLinkTask
            });
        }
      })
      .catch(function (error) {
        currentObj.log('catch Function of AJAX-Called... error()');
        currentObj.log(error);
        currentObj.apiResponse = error;
      });
     },

    scrollToTop() {
      window.scrollTo(0,0);
    },

  },
 
  watch: {
      flightcountselection_trigger: function () {
        this.log('App.vue: flightcountselection_trigger.changed();')
      }
  },
}
</script>
<style>
.pointer{
  cursor: pointer
}
.step-background-color{
  background-color: #ebebeb
}

input[type="tel" i] {
    font-size: 20px;
}
.logout-btn{
  background-color: transparent!important;
  padding: 0!important;
  text-decoration: underline!important;
}

</style>
